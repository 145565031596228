<template>
  <div class="wrapper-main__content placement-prohibitions-page">
    <div class="form-content">
      <div class="d-flex flex-wrap py-2 border-bottom">
        <VocabularyTitle :title="$t('sideBar.placementProhibitions')" :per="paginationData.per_page" :entries="paginationData.total" />
        <PaginateWrapper v-if="paginationData" :last-page="paginationData.last_page" :current="filterPage" class="ml-auto" @pageClick="pageClick" />

        <!--Search-->
        <div class="d-flex justify-content-between gap-3 mt-3 w-100">
          <div class="d-flex gap-3">
            <search-dropdown v-model="selectedPerPage" @onSelect="pageClick(1)" />

            <MultiSelect
              v-model="filters.brand_group"
              label="name"
              track-by="id"
              :options="filterGroupList"
              :placeholder="$t('table.selectGroup')"
              class="w-270px"
              @input="pageClick(1)"
            ></MultiSelect>
            <MultiSelect
              v-model="filters.genre"
              label="name"
              track-by="id"
              :options="filterGenresList"
              :placeholder="$t('table.selectGenre')"
              class="w-270px"
              @input="pageClick(1)"
            ></MultiSelect>
          </div>

          <search-input v-model="searchElements" @searchTable="pageClick(1)" />
        </div>
        <!--Search-->

        <div class="d-flex w-100 gap-3">
          <label class="ml-2 mb-0 mt-2">{{ $t('table.type') }}:</label>
          <b-form-group v-slot="{ ariaTypeFilterby }" class="mb-0 mt-2">
            <b-form-radio v-model="filters.type" inline :aria-describedby="ariaTypeFilterby" name="filter-type-radios" value="">
              {{ $t('table.all') }}
            </b-form-radio>
            <b-form-radio v-model="filters.type" inline :aria-describedby="ariaTypeFilterby" name="filter-type-radios" value="time">
              {{ $t('table.onlyTime') }}
            </b-form-radio>
            <b-form-radio v-model="filters.type" inline :aria-describedby="ariaTypeFilterby" name="filter-type-radios" value="genre">
              {{ $t('table.onlyGenre') }}
            </b-form-radio>
          </b-form-group>
        </div>
      </div>

      <!--TABLE-->
      <div class="table-responsive vocabulary-table">
        <table class="table table-hover" style="white-space: nowrap">
          <thead>
            <th scope="col">
              <sort-arrows :sort-string="sortString" :column="'name,'" @click="sortTable('name,', $event)" />
              {{ $t('table.name') }}
            </th>
            <th scope="col">
              <sort-arrows :sort-string="sortString" :column="'date_from,'" @click="sortTable('date_from,', $event)" />
              {{ $t('table.period') }}
            </th>
            <th scope="col">
              <sort-arrows :sort-string="sortString" :column="'genre_id,'" @click="sortTable('genre_id,', $event)" />
              {{ $t('sideBar.genres') }}
            </th>
            <th scope="col" style="min-width: 150px">
              <sort-arrows :sort-string="sortString" :column="'brand_group_id,'" @click="sortTable('brand_group_id,', $event)" />
              {{ $t('table.group') }}
            </th>
          </thead>
          <tbody class="overflow-hidden text-overflow-ellipsis">
            <tr v-if="placementProhibitionsStatus !== 'success'">
              <td colspan="4"><SpinnerLoader :loading="placementProhibitionsStatus" /></td>
            </tr>
            <tr v-for="(row, index) in tableData" :key="index">
              <td>
                <button
                  v-if="canView"
                  class="btn-icon"
                  :title="canEdit ? $t('table.edit') : $t('table.details')"
                  @click="showModalPlacementProhibitions('edit', row.id)"
                >
                  <b-icon icon="pencil"></b-icon>
                </button>
                <button v-if="canDelete" class="btn-icon mr-4" :title="$t('table.delete')" @click="showModalDeletePlacementProhibitions(row)">
                  <b-icon icon="trash"></b-icon>
                </button>
                {{ row.name }}
              </td>
              <td>
                {{ row.date_from | convertDate }} &ndash; {{ row.date_to | convertDate }} <br />
                {{ row.start_interval && row.end_interval ? sec2time(row.start_interval) + '-' + sec2time(row.end_interval) : '' }}
              </td>
              <td>
                {{ row.genre ? row.genre.name : '' }}
              </td>
              <td>
                {{ row.brand_group ? row.brand_group.name : '' }}
              </td>
            </tr>
            <tr v-if="placementProhibitionsStatus === 'success' && tableData && tableData.length === 0">
              <td colspan="4">
                <div class="d-flex w-100 justify-content-center">
                  {{ $t('table.noContent') }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--TABLE-->

      <!--FOOTER-->
      <div class="input-pagin">
        <b-button v-if="$checkPermissions('co_placement_prohibition.create')" variant="primary" @click="showModalPlacementProhibitions('add')">
          {{ $t('table.addProhibition') }}
        </b-button>
      </div>
      <!--FOOTER-->

      <!--MODAL ADD/EDIT-->
      <b-modal
        id="placement-prohibitions-modal"
        ref="placementProhibitions"
        size="lg"
        :title="getTitle()"
        :ok-disabled="$v.$invalid"
        :ok-title="modalName === 'add' ? $t('table.add') : $t('table.edit')"
        :cancel-title="$t('table.cancel')"
        :hide-footer="!canEdit"
        :busy="isModalBusy"
        @ok.prevent="showModalPlacementProhibitionsConfirm"
      >
        <SpinnerLoader v-if="isModalLoading" :loading="isModalLoading ? 'loading' : 'success'" />
        <form v-else @submit.stop.prevent="checkIfValidAddThenEnter">
          <input type="submit" value="Submit" class="hidden-submit" />
          <b-form-group :label="$t('table.writeTitle')" label-for="input-1-add">
            <b-form-input
              id="input-1-add"
              v-model.trim="modal.name"
              type="text"
              :placeholder="$t('table.enterTitle')"
              required
              autofocus
              :disabled="!canEdit"
            ></b-form-input>
          </b-form-group>

          <div class="d-flex w-100 justify-content-between">
            <b-form-group :label="$t('table.selectChannels')" style="width: 79%">
              <MultiSelect
                v-model="modal.channels"
                :options="channels ? channels.data : []"
                :multiple="true"
                :close-on-select="false"
                :placeholder="$t('table.selectChannels')"
                label="name"
                track-by="id"
                :disabled="modal.isAllChannels || !canEdit"
              ></MultiSelect>
            </b-form-group>
            <b-form-checkbox v-model="modal.isAllChannels" :disabled="!canEdit" name="check-button-channels" class="mr-2" style="margin-top: 2.3rem">
              {{ $t('table.selectAll') }}
            </b-form-checkbox>
          </div>

          <div class="d-flex w-100 justify-content-between">
            <b-form-group :label="$t('table.selectClass')" style="width: 49%">
              <MultiSelect
                v-model="modal.brand_class"
                :options="classes.data || []"
                :placeholder="$t('table.selectClass')"
                label="name"
                track-by="id"
                :disabled="!canEdit"
              ></MultiSelect>
            </b-form-group>
            <b-form-group :label="$t('table.selectGroups')" style="width: 49%">
              <MultiSelect
                v-model="modal.brand_group"
                :options="modal.group_list || []"
                :placeholder="$t('table.selectGroups')"
                label="name"
                track-by="id"
                :disabled="!canEdit || !modal.brand_class"
              ></MultiSelect>
            </b-form-group>
          </div>

          <b-form-group v-slot="{ ariaDescribedByTimeGenreSelect }" :label="$t('table.type')">
            <b-form-radio
              v-model="modal.isGenreProhibition"
              :aria-describedby="ariaDescribedByTimeGenreSelect"
              name="check-button-genre"
              :value="true"
              :disabled="modalName === 'edit' || !canEdit"
              inline
            >
              {{ $t('table.genreProhibition') }}
            </b-form-radio>
            <b-form-radio
              v-model="modal.isGenreProhibition"
              :aria-describedby="ariaDescribedByTimeGenreSelect"
              name="check-button-genre"
              :value="false"
              :disabled="modalName === 'edit' || !canEdit"
              inline
            >
              {{ $t('table.time') }}
            </b-form-radio>
          </b-form-group>

          <div v-if="modal.isGenreProhibition">
            <b-form-group :label="$t('table.selectGenre')">
              <MultiSelect
                v-model.trim="modal.genre"
                label="name"
                track-by="id"
                :options="genres.data"
                :placeholder="$t('table.selectGenre')"
                :disabled="!canEdit"
              ></MultiSelect>
            </b-form-group>
          </div>

          <div v-else class="d-flex w-100 justify-content-between">
            <b-form-group style="flex-basis: 49%" :label="$t('channelModal.from')" label-for="time-from-input-3">
              <BroadcastTimePicker id="time-from-input-3" v-model="modal.time_from" :disabled="!canEdit" required :interval-mode="true" />
            </b-form-group>
            <b-form-group style="flex-basis: 49%" :label="$t('channelModal.to')" label-for="time-to-input-4">
              <BroadcastTimePicker id="time-from-input-4" v-model="modal.time_to" :disabled="!canEdit" required :interval-mode="true" />
            </b-form-group>
          </div>

          <div class="d-flex w-100 justify-content-between">
            <b-form-group id="input-group-4" style="width: 49%" :label="$t('table.writeDateFrom')">
              <datepicker-wrapper id="datepicker-buttons-from" v-model="modal.date_from" required :disabled="!canEdit" />
            </b-form-group>
            <b-form-group id="input-group-4-2" style="width: 49%" :label="$t('table.writeDateTo')">
              <datepicker-wrapper id="datepicker-buttons-to" v-model="modal.date_to" required :min="modal.date_from" :disabled="!canEdit" />
            </b-form-group>
          </div>
        </form>
      </b-modal>

      <b-modal
        ref="placementProhibitions-confirm"
        size="sm"
        :title="modalName === 'add' ? $t('table.addPlacementProhibitions') : $t('table.editPlacementProhibitions')"
        :ok-title="$t('table.yes')"
        :cancel-title="$t('table.no')"
        auto-focus-button="ok"
        :busy="isModalBusy"
        @ok.prevent="addPlacementProhibitions"
      >
        <p class="my-2">
          {{ modalName === 'add' ? $t('table.confirmAddPlacementProhibitions') : $t('table.confirmEditPlacementProhibitions') }}
          <span class="text-danger">{{ modal.name }}</span>
          ?
        </p>
      </b-modal>
      <!--MODAL ADD/EDIT-->

      <!--MODAL DELETE-->
      <b-modal
        ref="delete-placementProhibitions-confirm"
        size="sm"
        :title="$t('table.deletePlacementProhibitions')"
        :ok-title="$t('table.yes')"
        :cancel-title="$t('table.no')"
        auto-focus-button="ok"
        :busy="isModalBusy"
        @ok.prevent="deletePlacementProhibitions"
      >
        <p class="my-2">
          {{ $t('table.confirmDeletePlacementProhibitions') }} <span class="text-danger">{{ rowId.name }}</span>
          ?
        </p>
      </b-modal>
      <!--MODAL DELETE-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import MultiSelect from '@/components/MultiSelect';
import VocabularyTitle from '@/components/VocabularyTitle';
import SpinnerLoader from '@/components/SpinnerLoader';
import errorsHandler from '@/utils/errorsHandler';
import PaginateWrapper from '../components/PaginateWrapper';
import SearchDropdown from '../components/SearchDropdown';
import SearchInput from '../components/SearchInput';
import convertDate from '@/filters/convertDate';
import sortTableMixin from '@/mixins/sortTable.js';
import adminPanelQuery from '@/mixins/adminPanelQuery.js';
import SortArrows from '../components/SortArrows';
import DatepickerWrapper from '@/components/DatepickerWrapper.vue';
import BroadcastTimePicker from '@/components/BroadcastTimePicker';
import timeConvertHelpers from '@/mixins/timeConvertHelpers';

export default {
  name: 'PlacementProhibitions',
  components: { PaginateWrapper, SpinnerLoader, VocabularyTitle, MultiSelect, SearchDropdown, SearchInput, SortArrows, DatepickerWrapper, BroadcastTimePicker },
  filters: {
    convertDate,
  },
  mixins: [sortTableMixin, adminPanelQuery, timeConvertHelpers],
  props: {
    q: { type: String, default: undefined },
    per_page: { type: [String, Number], default: undefined },
    on_page: { type: [String, Number], default: undefined },
    sort_mode: { type: String, default: undefined },
    brand_group_id: { type: [String, Number], default: undefined },
    genre_id: { type: [String, Number], default: undefined },
    selected_type: { type: String, default: undefined },
  },

  data() {
    return {
      modal: {
        brand_group: '',
        brand_class: '',
        group_list: [],
        genre: '',
        name: '',
        date_from: '',
        date_to: '',
        time_from: '',
        time_to: '',
        channels: [],
        isGenreProhibition: true,
        isAllChannels: false,
      },
      rowId: '',
      tableData: [],
      paginationData: '',
      searchElements: '',
      filterPage: 1,
      selectedPerPage: '100',
      modalName: '',
      filters: {
        brand_group: '',
        genre: '',
        type: '',
      },
      filterGroupList: [],
      filterGenresList: [],
      loading: true,
      isModalBusy: false,
      isModalLoading: true,
    };
  },
  computed: {
    ...mapGetters({
      isThemeHeader: 'isTheme',
      placementProhibitions: 'getPlacementProhibitions',
      placementProhibitionsStatus: 'getPlacementProhibitionsStatus',
      modalEditPlacementProhibitions: 'getModalEditPlacementProhibitions',
      genres: 'getGenres',
      channels: 'getChannels',
      classes: 'getClasses',
      modalEditClasses: 'getModalEditClasses',
      isLocale: 'isLocale',
      group: 'getGroup',
    }),
    canView: function () {
      return this.$checkPermissions('placement_prohibition.read');
    },
    canEdit: function () {
      return this.$checkPermissions('placement_prohibition.update');
    },
    canDelete: function () {
      return this.$checkPermissions('placement_prohibition.delete');
    },
  },

  validations() {
    if (this.modal.isGenreProhibition) {
      return {
        modal: {
          name: { required },
          brand_class: { required },
          brand_group: { required },
          genre: { required },
          date_from: { required },
          date_to: { required },
        },
      };
    } else {
      return {
        modal: {
          name: { required },
          brand_class: { required },
          brand_group: { required },
          date_from: { required },
          date_to: { required },
          time_from: { required },
          time_to: { required },
        },
      };
    }
  },
  watch: {
    placementProhibitions() {
      this.setTableData();
    },

    'filters.type'() {
      if (!this.loading) this.pageClick(1);
    },

    async 'modal.brand_class'() {
      if (this.modal.brand_class) {
        await this.$store.dispatch('GET_CLASSES_ID', this.modal.brand_class.id);
        this.modal.group_list = this.modalEditClasses.brand_groups;
        //clear group select if ID of its parent class differs
        if (this.modal.brand_group && this.modal.brand_group.brand_class_id !== this.modal.brand_class.id) {
          this.modal.brand_group = '';
        }
      } else {
        this.modal.brand_group = '';
        this.modal.group_list = [];
      }
    },
  },
  created() {
    document.title = this.$i18n.t('sideBar.placementProhibitions') + ' – OpenMediaLogic';
  },
  mounted() {
    this.getPlacementProhibitionInfo();
  },
  destroyed() {
    this.$store.commit('clearPlacementProhibitionsList');
    this.$store.commit('clearGenresList');
    this.$store.commit('clearClassesList');
    this.$store.commit('clearGroups');
  },
  methods: {
    async paramsData() {
      if (this.tableData) this.tableData.length = 0;
      await this.$store.dispatch('GET_PLACEMENT_PROHIBITIONS', {
        page: +this.filterPage,
        per_page: +this.selectedPerPage,
        include: 'brandGroup,genre,channels',
        'filter[name]': this.searchElements !== '' ? this.searchElements : null,
        'filter[brand_group_id]': this.filters.brand_group ? this.filters.brand_group.id : null,
        'filter[genre_id]': this.filters.genre ? this.filters.genre.id : null,
        'filter[of_genre]': this.filters.type === 'genre' ? this.filters.type : null,
        'filter[of_time]': this.filters.type === 'time' ? this.filters.type : null,
        sort: this.sortString,
      });
      this.updateQuery();
    },

    pageClick(page) {
      this.filterPage = page;
      this.paramsData();
    },

    // get table info
    setTableData() {
      if (this.placementProhibitions) {
        this.tableData = this.placementProhibitions.data;
        this.paginationData = this.placementProhibitions.pagination;
        if (this.filterPage > this.placementProhibitions.pagination.last_page) {
          this.filterPage = this.placementProhibitions.pagination.last_page;
          this.pageClick(this.filterPage);
        }
      }
    },

    async getPlacementProhibitionInfo() {
      await Promise.all([
        this.$store.dispatch('GET_GENRES', { per_page: 1000 }),
        this.$store.dispatch('GET_GROUP', { per_page: 1000 }),
        this.$store.dispatch('GET_CHANNELS', { per_page: 1000 }),
      ]);
      this.filterGenresList = [{ name: this.$i18n.t('table.all'), id: null }, ...this.genres.data];
      this.filterGroupList = [{ name: this.$i18n.t('table.all'), id: null }, ...this.group.data];
      await this.restoreFromQuery('prohibitions');
      await this.paramsData();
      this.loading = false;
    },

    getChannelsName(prop) {
      let names = '';
      prop.forEach((channel, index) => {
        const ch = this.channels.data.find((el) => el.id === channel);
        if (prop.length === index + 1) {
          names += ch.name;
        } else {
          names += ch.name + ', ';
        }
      });
      return names;
    },

    getTitle() {
      if (this.modalName === 'add') return this.$i18n.t('table.addNewPlacementProhibitions');
      else if (this.modalName === 'edit' && !this.canEdit) return this.modal.name;
      else return this.$i18n.t('table.editPlacementProhibitions');
    },

    clearData() {
      this.modal.brand_group = '';
      this.modal.brand_class = '';
      this.modal.group_list = [];
      this.modal.genre = '';
      this.modal.name = '';
      this.modal.date_from = '';
      this.modal.date_to = '';
      this.modal.time_from = '';
      this.modal.time_to = '';
      this.modal.channels = [];
      this.modal.isGenreProhibition = true;
      this.modal.isAllChannels = false;
    },

    // modal add/edit
    async showModalPlacementProhibitions(name, data) {
      this.isModalLoading = true;
      const id = data;
      this.modalName = name;
      this.$refs['placementProhibitions'].show();
      this.clearData();
      if (!(this.classes && this.classes.data.length > 0)) await this.$store.dispatch('GET_CLASSES', { per_page: 1000 });
      if (name === 'edit') {
        await this.$store.dispatch('GET_PLACEMENT_PROHIBITIONS_ID', { id: id, data: { include: 'channels' } });
        if (this.modalEditPlacementProhibitions) {
          this.modal.name = this.modalEditPlacementProhibitions.name;
          this.modal.brand_group = this.group.data.find((el) => el.id === this.modalEditPlacementProhibitions.brand_group_id);
          this.modal.brand_class = this.classes.data.find((el) => el.id === this.modal.brand_group.brand_class_id);

          if (this.modalEditPlacementProhibitions.genre_id) {
            this.modal.isGenreProhibition = true;
            this.modal.genre = this.genres.data.find((el) => el.id === this.modalEditPlacementProhibitions.genre_id);
          } else {
            this.modal.isGenreProhibition = false;
            this.modal.time_from = this.modalEditPlacementProhibitions.start_interval;
            this.modal.time_to = this.modalEditPlacementProhibitions.end_interval;
          }

          this.modal.date_from = this.modalEditPlacementProhibitions.date_from;
          this.modal.date_to = this.modalEditPlacementProhibitions.date_to;

          //activate checkbox Select all channels if empty array (=all)
          if (this.modalEditPlacementProhibitions.channel_ids.length === 0) {
            this.modal.isAllChannels = true;
          } else {
            this.modalEditPlacementProhibitions.channel_ids.forEach((el) => {
              this.modal.channels.push(this.channels.data.find((ch) => ch.id === el));
            });
          }
        }
      }
      this.isModalLoading = false;
    },

    hideModalPlacementProhibitions() {
      this.$refs['placementProhibitions'].hide();
    },
    showModalPlacementProhibitionsConfirm() {
      this.$refs['placementProhibitions-confirm'].show();
    },
    hideModalPlacementProhibitionsConfirm() {
      this.$refs['placementProhibitions-confirm'].hide();
    },
    async addPlacementProhibitions() {
      this.isModalBusy = true;
      let formData = {};
      let channelArray = [];
      if (!this.modal.isAllChannels && this.modal.channels.length !== 0) {
        this.modal.channels.forEach((el) => {
          channelArray.push(el.id);
        });
      } else {
        channelArray = null;
      }
      if (this.modal.isGenreProhibition) {
        formData = {
          brand_group_id: this.modal.brand_group.id,
          genre_id: this.modal.genre.id,
          name: this.modal.name,
          date_from: this.modal.date_from,
          date_to: this.modal.date_to,
          channels: channelArray,
          for_all_channels: this.modal.isAllChannels ? true : false,
        };
      } else {
        formData = {
          brand_group_id: this.modal.brand_group.id,
          name: this.modal.name,
          date_from: this.modal.date_from,
          date_to: this.modal.date_to,
          start_interval: this.modal.time_from,
          duration: this.modal.time_to - this.modal.time_from,
          channels: channelArray,
          for_all_channels: this.modal.isAllChannels ? true : false,
        };
      }
      if (this.modalName === 'add') {
        await this.$store.dispatch('POST_PLACEMENT_PROHIBITIONS', {
          formData,
          handler: () => {
            this.$notify({
              type: 'success',
              title: this.$i18n.t('alert.addPlacementProhibitions'),
              text: this.modal.name,
            });
            this.hideModalPlacementProhibitions();
            this.paramsData();
            this.clearData();
          },
          handlerError: (errors) => {
            errorsHandler(errors, this.$notify);
          },
        });
      } else {
        const id = this.modalEditPlacementProhibitions.id;
        await this.$store.dispatch('PUT_PLACEMENT_PROHIBITIONS', {
          id,
          formData,
          handler: () => {
            this.$notify({
              type: 'success',
              title: this.$i18n.t('alert.editPlacementProhibitions'),
              text: this.modal.name,
            });
            this.hideModalPlacementProhibitions();
            this.paramsData();
            this.clearData();
          },
          handlerError: (errors) => {
            errorsHandler(errors, this.$notify);
          },
        });
      }
      this.hideModalPlacementProhibitionsConfirm();
      this.isModalBusy = false;
    },

    // modal delete
    async showModalDeletePlacementProhibitions(data) {
      this.rowId = data;
      this.$refs['delete-placementProhibitions-confirm'].show();
    },
    hideModalDeletePlacementProhibitionsConfirm() {
      this.$refs['delete-placementProhibitions-confirm'].hide();
    },
    async deletePlacementProhibitions() {
      this.isModalBusy = true;
      const formData = this.rowId.id;
      await this.$store.dispatch('DELETE_PLACEMENT_PROHIBITIONS_ID', {
        formData,
        handler: () => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.deletePlacementProhibitions'),
            text: this.rowId.name,
          });
          this.paramsData();
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
        },
      });
      this.hideModalDeletePlacementProhibitionsConfirm();
      this.isModalBusy = false;
    },

    checkIfValidAddThenEnter() {
      if (!this.$v.$invalid && !this.isModalBusy) this.showModalPlacementProhibitionsConfirm();
    },
  },
};
</script>

<style lang="sass" scoped>
.placement-prohibitions-page .vocabulary-table
  max-height: calc(100vh - 191px)
</style>
